// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/pl/login-landscape.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login[data-v-4a4ec2f8]{overflow:hidden}.login .row[data-v-4a4ec2f8]{align-items:center}.login .landscape[data-v-4a4ec2f8]{height:100vh;margin:0;-o-object-fit:cover;object-fit:cover}.login .login-welcome[data-v-4a4ec2f8]{margin:0;font-size:42px}.login .login-messages[data-v-4a4ec2f8]{display:flex;justify-content:center;align-items:center}.login .login-messages .banner[data-v-4a4ec2f8]{margin:5px}.login .login-messages h4[data-v-4a4ec2f8]{margin:0}.login .login-messages--hasContent[data-v-4a4ec2f8]{min-height:70px}.login .login-messages .banner[data-v-4a4ec2f8],.login .login-messages .text-error[data-v-4a4ec2f8]{max-width:42%}.login .first-login-message .banner[data-v-4a4ec2f8]{margin-bottom:0;border-left:0}.login .first-login-message .banner[data-v-4a4ec2f8] code{font-size:12px;padding:0}.gutless[data-v-4a4ec2f8]{height:100vh;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.gutless>.span-6[data-v-4a4ec2f8]{overflow-y:auto;display:flex;flex-direction:column;height:100%;place-content:center}.locale-elector[data-v-4a4ec2f8]{position:absolute;bottom:30px}", ""]);
// Exports
module.exports = exports;
